import ApiError from './api-error';

interface OptionsType {
  method: string;
  headers: Headers;
  body?: string;
}

const apiHost = process.env.NEXT_PUBLIC_API_HOST;

function apiUrl(relativeUrl: string): string {
  return `${apiHost}${`/api/${relativeUrl}`.replace(/\/\//g, '/')}`;
}

async function rawRequest(
  url: string,
  params?: Record<string, unknown>,
  method = 'GET'
): Promise<Response> {
  const headers: HeadersInit = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };
  const options: OptionsType = {
    method,
    headers: new Headers(headers),
  };

  options.body = JSON.stringify(params);
  return fetch(apiUrl(url), options);
}

async function apiRequest<ResponseType>(
  url: string,
  params?: Record<string, unknown>,
  method = 'GET'
): Promise<ResponseType> {
  const response = await rawRequest(url, params, method);
  if (response.ok) {
    return response.json();
  }
  const statusCode = response.status;
  const errorResponse = await response.json();

  return Promise.reject(new ApiError({ statusCode, ...errorResponse }));
}

function getRequest<ResponseType>(url: string): Promise<ResponseType> {
  return apiRequest(url);
}

function postRequest<ResponseType>(
  url: string,
  params = {}
): Promise<ResponseType> {
  return apiRequest(url, params, 'POST');
}

function putRequest<ResponseType>(
  url: string,
  params = {}
): Promise<ResponseType> {
  return apiRequest(url, params, 'PUT');
}

function deleteRequest<ResponseType>(
  url: string,
  params = {}
): Promise<ResponseType> {
  return apiRequest(url, params, 'DELETE');
}

export { rawRequest, getRequest, postRequest, putRequest, deleteRequest };
