interface Props {
  imageUrl: string;
  webpImage: string | null;
  alt?: string;
  className?: string;
  wrapperClassName?: string;
}
const WebpImage: React.FC<Props> = ({
  imageUrl,
  webpImage,
  alt = '',
  className,
  wrapperClassName,
}) => {
  if (!imageUrl) {
    return null;
  }

  return (
    <picture className={wrapperClassName}>
      {webpImage ? <source srcSet={webpImage} type="image/webp" /> : null}
      <img src={imageUrl} alt={alt} className={className} />
    </picture>
  );
};

export default WebpImage;
