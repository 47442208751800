import qs from 'qs';

import type CategoryType from '../types/CategoryType';
import type ProductDetailsType from '../types/ProductDetailsType';
import type ProductType from '../types/ProductType';
import { getRequest } from '../utils/api-client';

interface CategoriesResponse {
  categories: Array<CategoryType>;
}

interface CategoryResponse {
  category: CategoryType;
}

interface ProductsResponse {
  products: Array<ProductType>;
  page: number;
  total_pages: number;
  total_count: number;
}

interface ProductResponse {
  product: ProductDetailsType;
}

const getCategories = async (): Promise<CategoriesResponse> => {
  return getRequest('/categories');
};

const getCategory = async (slug: string): Promise<CategoryResponse> => {
  return getRequest(`/categories/${slug}`);
};

const getProducts = async (
  params: {
    categoryId?: number;
    capacity?: number;
    query?: string;
  } = {},
  page: string | number = 1
): Promise<ProductsResponse> => {
  return getRequest(
    `/products?${qs.stringify({
      category_id: params.categoryId,
      capacity: params.capacity,
      query: params.query,
      page,
    })}`
  );
};

const getProduct = async (slug: string): Promise<ProductResponse> => {
  return getRequest(`/products/${slug}`);
};

const getSimilarProducts = async (slug: string): Promise<ProductsResponse> => {
  return getRequest(`/products/${slug}/similar-products`);
};

const productsService = {
  getCategories,
  getCategory,
  getProducts,
  getProduct,
  getSimilarProducts,
};
export default productsService;
