import type ErrorsType from '../types/ErrorsType';

class ApiError {
  name = 'ApiError';

  errors: { [key: string]: string } | undefined;

  statusCode: number;

  constructor(body: ErrorsType) {
    this.errors = body.errors;
    this.statusCode = body.statusCode;
  }
}

export default ApiError;
