import Link from 'next/link';

import { SettingsType } from '../../services/settingsService';
import CategoryType from '../../types/CategoryType';
import Button from './Button';
import WebpImage from './WebpImage';

import styles from './PageFooter.module.scss';

interface Props {
  categories: Array<CategoryType>;
  settings: SettingsType;
}

const PageFooter: React.FC<Props> = ({ categories, settings }) => {
  return (
    <footer className={styles.pageFooter}>
      <div className="container">
        <div className={styles.footerCols}>
          <div className={styles.footerInfo}>
            <WebpImage
              imageUrl="/images/footer-logo.png"
              webpImage="/images/footer-logo.webp"
              alt=""
            />
            <p>MOVI - Wysokiej jakości oleje i smary</p>
            <p>Masz pytania? Zadzwoń</p>
            <div className={styles.footerPhone}>
              {settings.contact_phone.toString()}
            </div>
          </div>
          <div className={styles.footerLinks}>
            <h4>Movi</h4>
            <ul>
              <li>
                <Link href="/s/o-nas">
                  <a>O nas</a>
                </Link>
              </li>
              <li>
                <Link href="/katalog">
                  <a>Dobierz olej</a>
                </Link>
              </li>
              <li>
                <Link href="/dystrybutorzy">
                  <a>Dystrybutorzy</a>
                </Link>
              </li>
              <li>
                <Link href="/kontakt">
                  <a>Kontakt</a>
                </Link>
              </li>
            </ul>
          </div>
          <div className={styles.footerLinks}>
            <h4>Produkty</h4>
            <ul>
              {categories.map((category) => (
                <li key={category.id}>
                  <Link href={`/katalog/${category.slug}`}>
                    <a>{category.name}</a>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className={styles.footerContact}>
            <h4>Kontakt</h4>

            <p>Oficjalny dystrybutor:</p>
            <address>
              Prager Sp. z o.o.
              <br />
              {settings.company_address.toString()}
              <br />
              {settings.company_zipcode.toString()}{' '}
              {settings.company_city.toString()}
            </address>
            <Button href="/kontakt">Wyślij wiadomość</Button>

            <div className={styles.footerSocial}>
              {settings.facebook_url ? (
                <Link href={settings.facebook_url.toString()}>
                  <a className={styles.footerSocialFacebook}></a>
                </Link>
              ) : null}
              {settings.instagram_url ? (
                <Link href={settings.instagram_url.toString()}>
                  <a className={styles.footerSocialInstagram}></a>
                </Link>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footerBottom}>
        <div className="container">
          <div className={styles.footerBottomLayout}>
            <div>
              <span>www.movioil.com</span> - wszelkie prawa zastrzeżone.{' '}
            </div>
            <div>
              Realizacja:{' '}
              <Link href="https://futurecode.pl">
                <a>
                  <WebpImage
                    imageUrl="/images/futurecode-logo.jpg"
                    webpImage="/images/futurecode-logo.webp"
                    alt="Tworzenie stron internetowych, aplikacji mobilnych"
                  />
                </a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default PageFooter;
