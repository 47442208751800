import { useEffect, useState } from 'react';

import Link from 'next/link';
import { useRouter } from 'next/router';

import Button from './Button';
import WebpImage from './WebpImage';

import styles from './PageHeader.module.scss';

const PageFooter: React.FC = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const router = useRouter();

  useEffect(() => {
    setMenuOpen(false);
  }, [router.asPath]);

  return (
    <>
      <header className={styles.pageHeader}>
        <Link href="/">
          <a>
            <WebpImage
              imageUrl="/images/logo.png"
              webpImage="/images/logo.webp"
              alt="Logo"
              className={styles.logoImage}
            />
          </a>
        </Link>
        <div
          className={[
            styles.navigationDrawer,
            isMenuOpen && styles.navigationDrawerOpen,
          ].join(' ')}
        >
          <WebpImage
            imageUrl="/images/logo.png"
            webpImage="/images/logo.webp"
            alt="Logo"
            className={styles.drawerLogo}
          />

          <ul className={styles.mainNavigation}>
            <li>
              <Link href="/s/o-nas">
                <a>O nas</a>
              </Link>
            </li>
            <li>
              <Link href="/katalog">
                <a>Produkty</a>
              </Link>
            </li>
            <li>
              <Link href="/dystrybutorzy">
                <a>Dystrybutorzy</a>
              </Link>
            </li>
            <li>
              <Link href="/kontakt">
                <a>Kontakt</a>
              </Link>
            </li>
          </ul>
        </div>
        <div
          className={styles.navigationDrawerOverlay}
          onClick={() => setMenuOpen(false)}
        ></div>
        <div className={styles.headerRight}>
          <Link href="/katalog">
            <a className={styles.headerSearch}></a>
          </Link>

          <Button href="/katalog" className={styles.headerButton}>
            dobierz olej »
          </Button>

          <a
            className={styles.mobileBars}
            onClick={() => setMenuOpen(!isMenuOpen)}
          ></a>
        </div>
      </header>
      <div className={styles.pageHeaderSpace}></div>
    </>
  );
};
export default PageFooter;
