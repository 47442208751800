import { getRequest } from '../utils/api-client';

export type SettingsType = Record<string, string | number>;

function getSettings(): Promise<{ settings: SettingsType }> {
  return getRequest('/settings');
}

const settingsService = {
  getSettings,
};

export default settingsService;
