import classnames from 'classnames/bind';
import FadeLoader from 'react-spinners/FadeLoader';

import styles from './Loader.module.scss';

interface Props {
  fullSize?: boolean;
}

const Loader: React.FC<Props> = ({ fullSize = false }) => {
  return (
    <div
      className={classnames(styles.loader, { [styles.loaderFull]: fullSize })}
    >
      <FadeLoader height="20px" width="5px" radius="5px" margin="2px" />
    </div>
  );
};

export default Loader;
