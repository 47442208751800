import Link from 'next/link';

import styles from './Button.module.scss';

type Props =
  | {
      children: React.ReactNode;
      className?: string;
      component: 'button';
      onClick: () => void;
      href?: undefined;
      type: 'button';
    }
  | {
      children: React.ReactNode;
      className?: string;
      component: 'button';
      onClick?: undefined;
      href?: undefined;
      type?: 'submit';
    }
  | {
      children: React.ReactNode;
      className?: string;
      component?: 'link';
      href?: string;
      onClick?: undefined;
      type?: undefined;
    };

const Button: React.FC<Props> = ({
  children,
  className,
  component = 'link',
  onClick,
  href,
  type = 'submit',
}) => {
  if (component === 'button' && onClick) {
    return (
      <button
        type={type}
        className={[styles.button, className].join(' ')}
        onClick={onClick}
      >
        {children}
      </button>
    );
  }
  return (
    <Link href={href || '/'}>
      <a className={[styles.button, className].join(' ')}>{children}</a>
    </Link>
  );
};
export default Button;
