import { useEffect, useState } from 'react';

import type { AppProps } from 'next/app';
import Head from 'next/head';
import Link from 'next/link';
import { Router } from 'next/router';

import CookieLayer from '../components/layout/cookies-layer';
import Loader from '../components/layout/Loader';
import PageFooter from '../components/layout/PageFooter';
import PageHeader from '../components/layout/PageHeader';
import productsService from '../services/productsService';
import settingsService, { SettingsType } from '../services/settingsService';
import CategoryType from '../types/CategoryType';
import '../styles/globals.scss';

type Props = AppProps & {
  appSettings: SettingsType;
  categories: Array<CategoryType>;
};

function MyApp({ Component, pageProps, appSettings, categories }: Props) {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const startLoading: () => void = () => setLoading(true);
    const finishLoading: () => void = () => setLoading(false);

    Router.events.on('routeChangeStart', startLoading);
    Router.events.on('routeChangeComplete', finishLoading);

    return () => {
      Router.events.off('routeChangeStart', startLoading);
      Router.events.off('routeChangeComplete', finishLoading);
    };
  }, []);
  return (
    <>
      <Head>
        <title>MoviOil</title>
      </Head>
      <PageHeader />
      <Component {...pageProps} appSettings={appSettings} />
      <PageFooter settings={appSettings} categories={categories.slice(0, 4)} />
      <CookieLayer>
        Strona korzysta z plików cookies w celach statystycznych zgodnie z
        Polityką Prywatności. Możesz samodzielnie określić warunki
        przechowywania lub dostępu plików cookies w Twojej przeglądarce.{' '}
        <Link href="/polityka-prywatnosci">
          <a>Polityka Prywatności</a>
        </Link>
      </CookieLayer>
      {loading && <Loader fullSize />}
    </>
  );
}

MyApp.getInitialProps = async () => {
  const { settings } = await settingsService.getSettings();
  const { categories } = await productsService.getCategories();
  return {
    appSettings: settings,
    categories,
  };
};

export default MyApp;
