import { useEffect, useState } from 'react';

import { getCookie, setCookies } from 'cookies-next';

import Button from './Button';

import styles from './cookies-layer.module.scss';

interface Props {
  children: React.ReactNode;
}

const COOKIE_KEY = 'movioil-cookie-layer';

const CookieLayer: React.FC<Props> = ({ children }) => {
  const [showCookieLayer, setShowCookieLayer] = useState(false);

  useEffect(() => {
    setShowCookieLayer(getCookie(COOKIE_KEY) !== '1');
  }, []);

  const handleAccept = () => {
    setCookies(COOKIE_KEY, '1', {
      maxAge: 60 * 60 * 24 * 365,
    });
    setShowCookieLayer(false);
  };

  return (
    <div
      className={[
        styles.cookiesLayer,
        showCookieLayer && styles.cookiesLayerVisible,
      ].join(' ')}
    >
      <div className="container">
        <div className={styles.cookiesLayerWrapper}>
          <div className={styles.cookiesLayerText}>{children}</div>
          <div className={styles.cookiesLayerButtons}>
            <Button component="button" type="button" onClick={handleAccept}>
              Rozumiem
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookieLayer;
